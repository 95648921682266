<template>
  <div>
    <commingSoon />
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'camelTrade',
  mounted () {
    core.index()
  },
  data () {
    return {
    }
  }
}
</script>
